import { render, staticRenderFns } from "./CoverPage.vue?vue&type=template&id=52335a94&scoped=true"
import script from "./CoverPage.vue?vue&type=script&lang=js"
export * from "./CoverPage.vue?vue&type=script&lang=js"
import style0 from "./CoverPage.vue?vue&type=style&index=0&id=52335a94&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52335a94",
  null
  
)

export default component.exports
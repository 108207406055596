<template>
  <v-container fluid grid-list-xs fill-height class="container">
    <v-layout row wrap class="layout header_bg" fluid>
      <v-flex xs12 class="row_title">
        <v-layout
          row
          style="
            flex-flow: row;
            background-color: GhostWhite;
            padding-top: 10;
            font-weight: bold;
          "
          class="grey lighten-5"
        >
          <v-flex xs4 class="text-left ml-0 pl-0 mb-2 mt-1">
            <v-btn icon @click="OnBackToReport()">
              <v-icon color="pink lighten-1" large
                >mdi-arrow-left-bold-circle</v-icon
              >
            </v-btn>
            ใบปะหน้า
          </v-flex>

          <v-flex
            xs8
            class="text-right mr-1 mt-2 mr-2 pink--text text--darken-1"
          >
            {{ HostID }} {{ LineID }} {{ AgentID }}</v-flex
          >
        </v-layout>
        <v-divider class="pink lighten-1"></v-divider>
      </v-flex>

      <v-flex xs12>
        <v-layout
          row
          style="flex-flow: row; font-weight: bold"
          class="my-1 py-0"
        >
          <v-flex xs6 class="text-left"> ชื่อ : {{ CusName }}</v-flex>

          <v-flex xs6 class="text-right">
            งวด {{ getFullPeriodThai(PeriodDT) }}
          </v-flex>
        </v-layout>

        <v-simple-table class="mt-3" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="border" width="25%">รายการ</th>
                <th class="border text-right pr-5" width="25%">จำนวนเงิน</th>
                <th class="border text-right pr-5" width="25%">เปอร์เซ็นต์</th>
                <th class="border text-right pr-5 border-right" width="25%">
                  คงเหลือ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">ยอดรวม</td>
                <td class="border text-right pr-5">
                  {{ add_comma(Math.ceil(BetSumAll)) }}
                </td>
                <td class="border text-right pr-5">
                  {{ add_comma(Math.ceil(PercentAll)) }}
                </td>
                <td class="border border-right text-right pr-5">
                  {{ add_comma(Math.ceil(BetSumAll - PercentAll)) }}
                </td>
              </tr>
              <tr>
                <td class="border-bottom">ยอดลอย</td>
                <td class="border-bottom text-right pr-5">
                  {{ add_comma(Math.ceil(BetSumLoy)) }}
                </td>
                <td class="border-bottom text-right pr-5">
                  {{ add_comma(Math.ceil(PercentLoy)) }}
                </td>
                <td class="border-bottom border-right text-right pr-5">
                  {{ add_comma(Math.ceil(BetSumLoy - PercentLoy)) }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="3"
                  class="text-right pr-5"
                  style="border-top: 1px black solid"
                >
                  เงินทุนซื้อ
                </td>
                <td class="border-bottom border-right text-right pr-5">
                  {{
                    add_comma(
                      Math.ceil(BetSumAll - PercentAll) +
                        Math.ceil(BetSumLoy - PercentLoy)
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-simple-table class="mt-3" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="border text-left" width="25%">รายการถูก</th>
                <th class="border text-right pr-5" width="25%">จำนวนถูก</th>
                <th class="border text-right pr-5" width="25%">บาทละ</th>
                <th class="border text-right pr-5 border-right" width="25%">
                  เป็นเงิน
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in Prize3Top" :key="i">
                <td class="border">3 ตัวตรง</td>
                <td class="border text-center">
                  {{ item.BetWin == 0 ? "" : add_comma(item.BetWin) }}
                </td>
                <td class="border text-center">{{ item.Rate }}</td>
                <td class="border border-right text-right pr-5">
                  {{ item.GetPrize == 0 ? "" : add_comma(item.GetPrize) }}
                </td>
              </tr>
              <tr v-for="(item, i) in Prize3Tod" :key="'A' + i">
                <td class="border-bottom">3 ตัวโต๊ด</td>
                <td class="border text-center">
                  {{ item.BetWin == 0 ? "" : add_comma(item.BetWin) }}
                </td>
                <td class="border text-center">{{ item.Rate }}</td>
                <td class="border border-right text-right pr-5">
                  {{ item.GetPrize == 0 ? "" : add_comma(item.GetPrize) }}
                </td>
              </tr>
              <tr v-for="(item, i) in Prize2Top" :key="'B' + i">
                <td class="border-bottom">2 ตัวบน</td>
                <td class="border text-center">
                  {{ item.BetWin == 0 ? "" : add_comma(item.BetWin) }}
                </td>
                <td class="border text-center">{{ item.Rate }}</td>
                <td class="border border-right text-right pr-5">
                  {{ item.GetPrize == 0 ? "" : add_comma(item.GetPrize) }}
                </td>
              </tr>

              <tr v-for="(item, i) in Prize2Down" :key="'C' + i">
                <td class="border-bottom">2 ตัวล่าง</td>
                <td class="border text-center">
                  {{ item.BetWin == 0 ? "" : add_comma(item.BetWin) }}
                </td>
                <td class="border text-center">{{ item.Rate }}</td>
                <td class="border border-right text-right pr-5">
                  {{ item.GetPrize == 0 ? "" : add_comma(item.GetPrize) }}
                </td>
              </tr>

              <tr v-for="(item, i) in PrizeKutod" :key="'D' + i">
                <td class="border-bottom">คู่โต๊ดบน</td>
                <td class="border text-center">
                  {{ item.BetWin == 0 ? "" : add_comma(item.BetWin) }}
                </td>
                <td class="border text-center">{{ item.Rate }}</td>
                <td class="border border-right text-right pr-5">
                  {{ item.GetPrize == 0 ? "" : add_comma(item.GetPrize) }}
                </td>
              </tr>

              <tr v-for="(item, i) in PrizePuang" :key="'E' + i">
                <td class="border-bottom">พวง</td>
                <td class="border text-center">
                  {{ item.BetWin == 0 ? "" : add_comma(item.BetWin) }}
                </td>
                <td class="border text-center">{{ item.Rate }}</td>
                <td class="border border-right text-right pr-5">
                  {{ item.GetPrize == 0 ? "" : add_comma(item.GetPrize) }}
                </td>
              </tr>

              <tr v-for="(item, i) in PrizeMoon" :key="'F' + i">
                <td class="border-bottom pr-0">หมุน 4 ครั้ง</td>
                <td class="border text-center">
                  {{ item.BetWin == 0 ? "" : add_comma(item.BetWin) }}
                </td>
                <td class="border text-center">{{ item.Rate }}</td>
                <td class="border border-right text-right pr-5">
                  {{ item.GetPrize == 0 ? "" : add_comma(item.GetPrize) }}
                </td>
              </tr>

              <tr v-for="(item, i) in PrizeJom" :key="'G' + i">
                <td class="border-bottom">จม</td>
                <td class="border text-center">
                  {{ item.BetWin == 0 ? "" : add_comma(item.BetWin) }}
                </td>
                <td class="border text-center">{{ item.Rate }}</td>
                <td class="border border-right text-right pr-5">
                  {{ item.GetPrize == 0 ? "" : add_comma(item.GetPrize) }}
                </td>
              </tr>

              <tr v-for="(item, i) in PrizeLoybon" :key="'H' + i">
                <td class="border-bottom">ลอยบน</td>
                <td class="border text-center">
                  {{ item.BetWin == 0 ? "" : add_comma(item.BetWin) }}
                </td>
                <td class="border text-center">{{ item.Rate }}</td>
                <td class="border border-right text-right pr-5">
                  {{ item.GetPrize == 0 ? "" : add_comma(item.GetPrize) }}
                </td>
              </tr>

              <tr v-for="(item, i) in PrizeLoyLang" :key="'I' + i">
                <td class="border-bottom">ลอยล่าง</td>
                <td class="border text-center">
                  {{ item.BetWin == 0 ? "" : add_comma(item.BetWin) }}
                </td>
                <td class="border text-center">{{ item.Rate }}</td>
                <td class="border border-right text-right pr-5">
                  {{ item.GetPrize == 0 ? "" : add_comma(item.GetPrize) }}
                </td>
              </tr>

              <tr>
                <td colspan="3" align="end" style="border-top: 1px black solid">
                  เงินถูกรวม
                </td>
                <td class="border-bottom border-right text-right pr-5">
                  {{ add_comma(WinSum) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>

      <v-flex xs12>
        <v-layout
          row
          style="flex-flow: row; padding-top: 10; font-weight: bold"
          class="mt-2"
        >
          <v-flex xs5 class="text-right"> เงินทุนซื้อ </v-flex>

          <v-flex
            xs3
            class="text-right pr-5"
            style="border-bottom: 1px dotted grey"
          >
            {{
              add_comma(
                Math.ceil(BetSumAll - PercentAll) +
                  Math.ceil(BetSumLoy - PercentLoy)
              )
            }}
          </v-flex>
          <v-flex xs4> บาท </v-flex>
        </v-layout>
        <v-layout
          row
          style="flex-flow: row; font-weight: bold; color: red"
          class="my-0 py-0"
        >
          <v-flex xs5 class="text-right"> เงินถูกรวม </v-flex>

          <v-flex
            xs3
            class="text-right pr-5"
            style="border-bottom: 1px dotted red"
          >
            {{ add_comma(WinSum) }}
          </v-flex>
          <v-flex xs4> บาท </v-flex>
        </v-layout>
        <v-layout
          row
          style="flex-flow: row; font-weight: bold"
          class="my-0 py-0"
        >
          <v-flex xs5 class="text-right"> {{ getSummaryText() }} </v-flex>

          <v-flex
            xs3
            class="text-right pr-5"
            style="border-bottom: 1px solid grey"
          >
            {{ getSummaryPrice() }}
          </v-flex>
          <v-flex xs4> บาท </v-flex>
        </v-layout>
        <v-layout
          row
          style="flex-flow: row; font-weight: bold"
          class="my-0 py-0"
        >
          <v-flex xs5 class="text-right"> ค้างเก่า </v-flex>

          <v-flex
            xs3
            class="text-right pr-5"
            style="border-bottom: 1px dotted grey"
          >
          </v-flex>
          <v-flex xs4> บาท </v-flex>
        </v-layout>
        <v-layout
          row
          style="flex-flow: row; font-weight: bold"
          class="my-0 py-0"
        >
          <v-flex xs5 class="text-right"> รวมค้าง </v-flex>

          <v-flex
            xs3
            class="text-right pr-5"
            style="border-bottom: 1px solid grey"
          >
          </v-flex>
          <v-flex xs4> บาท </v-flex>
        </v-layout>
        <!-- <v-divider class="pink lighten-1"></v-divider> -->
      </v-flex>
    </v-layout>
  </v-container>

  <!-- <div>ตาราง</div> -->

  <!-- <div>ยอดลอย</div> -->
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

// import ReportRow from "@/components/Report/CoverPage/Row";

export default {
  name: "Pages_Main",
  mixins: [globalFunctionMixin],
  components: {
    //component name,
    // ReportRow,
  },
  computed: {
    // // //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    // PriceSum() {
    //   return parseInt(this.$store.getters["page/PageData"].PriceSum);
    // },
    // PriceLoy() {
    //   return parseInt(this.$store.getters["page/PageData"].PriceSumLoy);
    // },
    // PageStatus() {
    //   return parseInt(this.$store.getters["page/PageData"].Status);
    // },
    // UpdateDT() {
    //   return this.$store.getters["page/PageData"].UpdateDT;
    // },
  },
  mounted() {
    this.ckLogin();
    window.scrollTo(0, 0);
    //   this.PageNumber = this.$route.query.PageNumber;
    //   this.CusID = this.$route.query.CusID;
    //   // this.updateDataTableHeight();
    //   setTimeout(() => {
    //     this.updateDataTableHeight();
    //   }, 500);
    this.GetData();
  },
  methods: {
    async GetData() {
      let parameter = {
        to: "Report",
        method: "cover_page",
        PeriodDT: this.PeriodDT,
      };

      await this.$store.dispatch("page/TransferData", parameter).then(
        (response) => {
          this.CusName = response.AGENT_NAME;
          this.Prize3Top = [];
          this.Prize3Tod = [];
          this.Prize2Top = [];
          this.Prize2Down = [];
          this.PrizeKutod = [];
          this.PrizePuang = [];
          this.PrizeMoon = [];
          this.PrizeJom = [];
          this.PrizeLoybon = [];
          this.PrizeLoyLang = [];
          this.BetSumAll = 0;
          this.BetSumLoy = 0;
          this.PercentAll = 0;
          this.PercentLoy = 0;
          this.SummaryAll = 0;
          this.SummmaryLoy = 0;
          this.WinSum = 0;

          let ReportItem = [];

          response.REPORT_PAGE.forEach((rp_item) => {
            ReportItem = [];

            this.WinSum += parseInt(rp_item.ALL_BETGET);

            switch (parseInt(rp_item.BetTypeShowID)) {
              case 1:
                ReportItem["BetWin"] = rp_item.ALL_BETWIN;
                ReportItem["Rate"] = rp_item.PrizeRate;
                ReportItem["BetSum"] = parseInt(rp_item.ALL_BETGET);
                this.Prize3Top.push(ReportItem);
                this.BetSumAll += parseInt(rp_item.ALL_BETSUM);
                this.PercentAll +=
                  (parseInt(rp_item.ALL_BETSUM) *
                    response.PRIZE_PERCENT[0]["AgentPercent"]) /
                  100;
                break;
              case 2:
                ReportItem["BetWin"] = rp_item.ALL_BETWIN;
                ReportItem["Rate"] = rp_item.PrizeRate;
                ReportItem["GetPrize"] = parseInt(rp_item.ALL_BETGET);
                this.Prize3Tod.push(ReportItem);
                this.BetSumAll += parseInt(rp_item.ALL_BETSUM);
                this.PercentAll +=
                  (parseInt(rp_item.ALL_BETSUM) *
                    response.PRIZE_PERCENT[1]["AgentPercent"]) /
                  100;
                break;
              case 3:
                ReportItem["BetWin"] = rp_item.ALL_BETWIN;
                ReportItem["Rate"] = rp_item.PrizeRate;
                ReportItem["GetPrize"] = parseInt(rp_item.ALL_BETGET);
                this.Prize2Top.push(ReportItem);
                this.BetSumAll += parseInt(rp_item.ALL_BETSUM);
                this.PercentAll +=
                  (parseInt(rp_item.ALL_BETSUM) *
                    response.PRIZE_PERCENT[2]["AgentPercent"]) /
                  100;
                break;
              case 4:
                ReportItem["BetWin"] = rp_item.ALL_BETWIN;
                ReportItem["Rate"] = rp_item.PrizeRate;
                ReportItem["GetPrize"] = parseInt(rp_item.ALL_BETGET);
                this.Prize2Down.push(ReportItem);
                this.BetSumAll += parseInt(rp_item.ALL_BETSUM);
                this.PercentAll +=
                  (parseInt(rp_item.ALL_BETSUM) *
                    response.PRIZE_PERCENT[3]["AgentPercent"]) /
                  100;
                break;
              case 5:
                ReportItem["BetWin"] = rp_item.ALL_BETWIN;
                ReportItem["Rate"] = rp_item.PrizeRate;
                ReportItem["GetPrize"] = parseInt(rp_item.ALL_BETGET);
                this.PrizeKutod.push(ReportItem);
                this.BetSumAll += parseInt(rp_item.ALL_BETSUM);
                this.PercentAll +=
                  (parseInt(rp_item.ALL_BETSUM) *
                    response.PRIZE_PERCENT[4]["AgentPercent"]) /
                  100;
                break;
              case 6:
                ReportItem["BetWin"] = rp_item.ALL_BETWIN;
                ReportItem["Rate"] = rp_item.PrizeRate;
                ReportItem["GetPrize"] = parseInt(rp_item.ALL_BETGET);
                this.PrizePuang.push(ReportItem);
                this.BetSumAll += parseInt(rp_item.ALL_BETSUM);
                this.PercentAll +=
                  (parseInt(rp_item.ALL_BETSUM) *
                    response.PRIZE_PERCENT[5]["AgentPercent"]) /
                  100;
                break;
              case 7:
                ReportItem["BetWin"] = rp_item.ALL_BETWIN;
                ReportItem["Rate"] = rp_item.PrizeRate;
                ReportItem["GetPrize"] = parseInt(rp_item.ALL_BETGET);
                this.PrizeMoon.push(ReportItem);
                this.BetSumAll += parseInt(rp_item.ALL_BETSUM);
                this.PercentAll +=
                  (parseInt(rp_item.ALL_BETSUM) *
                    response.PRIZE_PERCENT[6]["AgentPercent"]) /
                  100;
                break;
              case 8:
                ReportItem["BetWin"] = rp_item.ALL_BETWIN;
                ReportItem["Rate"] = rp_item.PrizeRate;
                ReportItem["GetPrize"] = parseInt(rp_item.ALL_BETGET);
                this.PrizeJom.push(ReportItem);
                this.BetSumLoy += parseInt(rp_item.ALL_BETSUM);
                this.PercentLoy +=
                  (parseInt(rp_item.ALL_BETSUM) *
                    response.PRIZE_PERCENT[7]["AgentPercent"]) /
                  100;
                break;
              case 9:
                ReportItem["BetWin"] = rp_item.ALL_BETWIN;
                ReportItem["Rate"] = rp_item.PrizeRate;
                ReportItem["GetPrize"] = parseInt(rp_item.ALL_BETGET);
                this.PrizeLoybon.push(ReportItem);
                this.BetSumLoy += parseInt(rp_item.ALL_BETSUM);
                this.PercentLoy +=
                  (parseInt(rp_item.ALL_BETSUM) *
                    response.PRIZE_PERCENT[8]["AgentPercent"]) /
                  100;
                break;
              case 10:
                ReportItem["BetWin"] = rp_item.ALL_BETWIN;
                ReportItem["Rate"] = rp_item.PrizeRate;
                ReportItem["GetPrize"] = parseInt(rp_item.ALL_BETGET);
                this.PrizeLoyLang.push(ReportItem);
                this.BetSumLoy += parseInt(rp_item.ALL_BETSUM);
                this.PercentLoy +=
                  (parseInt(rp_item.ALL_BETSUM) *
                    response.PRIZE_PERCENT[9]["AgentPercent"]) /
                  100;
                break;
            }
          });

          if (this.Prize3Top.length == 0) {
            ReportItem = [];
            ReportItem["BetWin"] = 0;
            ReportItem["Rate"] = response.PRIZE_PERCENT[0]["PrizeRate"];
            ReportItem["GetPrize"] = 0;
            this.Prize3Top.push(ReportItem);
          }
          if (this.Prize3Tod.length == 0) {
            ReportItem = [];
            ReportItem["BetWin"] = 0;
            ReportItem["Rate"] = response.PRIZE_PERCENT[0]["PrizeRate"];
            ReportItem["GetPrize"] = 0;
            this.Prize3Tod.push(ReportItem);
          }
          if (this.Prize2Top.length == 0) {
            ReportItem = [];
            ReportItem["BetWin"] = 0;
            ReportItem["Rate"] = response.PRIZE_PERCENT[0]["PrizeRate"];
            ReportItem["GetPrize"] = 0;
            this.Prize2Top.push(ReportItem);
          }
          if (this.Prize2Down.length == 0) {
            ReportItem = [];
            ReportItem["BetWin"] = 0;
            ReportItem["Rate"] = response.PRIZE_PERCENT[0]["PrizeRate"];
            ReportItem["GetPrize"] = 0;
            this.Prize2Down.push(ReportItem);
          }
          if (this.PrizeKutod.length == 0) {
            ReportItem = [];
            ReportItem["BetWin"] = 0;
            ReportItem["Rate"] = response.PRIZE_PERCENT[0]["PrizeRate"];
            ReportItem["GetPrize"] = 0;
            this.PrizeKutod.push(ReportItem);
          }
          if (this.PrizePuang.length == 0) {
            ReportItem = [];
            ReportItem["BetWin"] = 0;
            ReportItem["Rate"] = response.PRIZE_PERCENT[0]["PrizeRate"];
            ReportItem["GetPrize"] = 0;
            this.PrizePuang.push(ReportItem);
          }
          if (this.PrizeMoon.length == 0) {
            ReportItem = [];
            ReportItem["BetWin"] = 0;
            ReportItem["Rate"] = response.PRIZE_PERCENT[0]["PrizeRate"];
            ReportItem["GetPrize"] = 0;
            this.PrizeMoon.push(ReportItem);
          }
          if (this.PrizeJom.length == 0) {
            ReportItem = [];
            ReportItem["BetWin"] = 0;
            ReportItem["Rate"] = response.PRIZE_PERCENT[0]["PrizeRate"];
            ReportItem["GetPrize"] = 0;
            this.PrizeJom.push(ReportItem);
          }
          if (this.PrizeLoybon.length == 0) {
            ReportItem = [];
            ReportItem["BetWin"] = 0;
            ReportItem["Rate"] = response.PRIZE_PERCENT[0]["PrizeRate"];
            ReportItem["GetPrize"] = 0;
            this.PrizeLoybon.push(ReportItem);
          }
          if (this.PrizeLoyLang.length == 0) {
            ReportItem = [];
            ReportItem["BetWin"] = 0;
            ReportItem["Rate"] = response.PRIZE_PERCENT[0]["PrizeRate"];
            ReportItem["GetPrize"] = 0;
            this.PrizeLoyLang.push(ReportItem);
          }
        },
        (error) => {}
      );
    },
    getSummaryPrice() {
      return this.add_comma(
        Math.abs(
          Math.ceil(this.BetSumAll - this.PercentAll) +
            Math.ceil(this.BetSumLoy - this.PercentLoy) -
            this.WinSum
        )
      );
    },
    getSummaryText() {
      if (
        Math.ceil(this.BetSumAll - this.PercentAll) +
          Math.ceil(this.BetSumLoy - this.PercentLoy) -
          this.WinSum >
        0
      )
        return "เก็บส่งเจ้า";
      return "เจ้าจ่าย";
    },
    OnBackToReport() {
      this.$router.push({
        path: "Reports",
      });
    },
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),
    CusID: "",
    CusName: "",
    PageNumber: 0,

    Prize3Top: [],
    Prize3Tod: [],
    Prize2Top: [],
    Prize2Down: [],
    PrizeKutod: [],
    PrizePuang: [],
    PrizeMoon: [],
    PrizeJom: [],
    PrizeLoybon: [],
    PrizeLoyLang: [],

    BetSumAll: 0,
    BetSumLoy: 0,
    PercentAll: 0,
    PercentLoy: 0,
    SummaryAll: 0,
    SummmaryLoy: 0,
    WinSum: 0,

    CloseTime: "",
    PeriodStatus: 0,
    PeriodIsOpen: 0,

    dataTableHeight: 0,
  }),
};
</script>

<style scoped>
.container {
  background-color: white;
  flex-grow: 1;
  flex-shrink: 0;
}
.layout {
  flex-flow: column;
  padding: 2px;
}
.row_title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 100%;
  flex: 0 1;
}

.border {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.border-bottom {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
}
.border-right {
  border-right: 1px solid black;
}
</style>
